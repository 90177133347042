import ApiService from './api.service'

const RoomService = {
  get () {
    return ApiService.get('rooms')
  },
  getSingle (room) {
    return ApiService.get(`rooms/${room}`)
  },
  getByClinic (clinicId) {
    return ApiService.get(`rooms/clinic/${clinicId}`)
  },
  getScheduleByClinic (clinicId, businessDate) {
    return ApiService.query(`rooms/clinic/${clinicId}/schedule`, { businessDate: businessDate })
  },
  getSchedule (roomId) {
    return ApiService.get(`rooms/schedule/${roomId}`)
  },
  post (room) {
    return ApiService.post('rooms', room)
  },
  postSchedule (schedule) {
    return ApiService.post('rooms/schedule', schedule)
  },
  put (room) {
    return ApiService.put(`rooms/${room.id}`, room)
  },
  putSchedule (schedule) {
    return ApiService.put(`rooms/schedule/${schedule.id}`, schedule)
  },
  patch (room) {
    return ApiService.patch(`rooms/${room.id}`, room)
  },
  delete (room) {
    return ApiService.delete(`rooms/${room.id}`)
  }
}

export default RoomService
