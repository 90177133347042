const state = {
  snackbar: {
    show: false,
    message: '',
    color: '',
    top: null,
    bottom: null,
    multiLine: true,
    timeout: 5000
  }
}

const getters = {}

const actions = {}

const mutations = {
  setSnackbar (state, snackbar) {
    state.snackbar.show = snackbar.show
    state.snackbar.message = snackbar.message
    state.snackbar.color = snackbar.color
    state.snackbar.top = snackbar.top === undefined ? null : 'top'
    state.snackbar.bottom = snackbar.bottom === undefined ? null : 'bottom'
    state.snackbar.timeout = snackbar.timeout === undefined ? 5000 : snackbar.timeout
  },
  setSnackbarShow (state, value) {
    state.snackbar.show = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
