import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import { Role } from "./helpers";
import { TokenCheckService } from "./services/api.service";
import BookingSchedule from "@/views/portal/scheduling/BookingSchedule";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "ViewIndex",
      component: () => import("@/views/Index"),
    },
    {
      path: "/help",
      beforeEnter() {
        location.href = "https://www.expressmedicals.co.uk/imms-user-guide";
      },
    },
    {
      // If a user has book marked the login page, redirect them
      path: "/login",
      redirect: "/",
    },
    {
      path: "/confirm/:id",
      name: "Confirm",
      component: () => import("@/views/pages/Confirm"),
    },
    {
      path: "/reset",
      name: "Reset",
      component: () => import("@/views/pages/Reset"),
    },
    {
      path: "/cardinfo/:id",
      name: "CardDetails",
      component: () => import("@/views/pages/PaymentDetails"),
      props: true,
    },
    {
      path: "/cardthanks",
      name: "PaymentDetailsThanks",
      component: () => import("@/views/pages/PaymentDetailsThanks"),
      props: true,
    },
    {
      path: "/thankyou/:booking",
      name: "ThankYou",
      props: true,
      component: () => import("@/views/pages/ThankYou"),
    },
    {
      path: "/newpassword",
      name: "ChangePassword",
      props: true,
      component: () => import("@/views/pages/ChangePassword"),
      authorize: [
        Role.Admin,
        Role.Imms,
        Role.Staff,
        Role.Finance,
        Role.Medic,
        Role.Receptionist,
        Role.CustomerAdmin,
        Role.Customer,
        Role.Candidate,
      ],
    },
    {
      path: "/mfasetup",
      name: "MfaSetup",
      component: () => import("@/views/pages/MfaSetup"),
      authorize: [
        Role.Admin,
        Role.Imms,
        Role.Staff,
        Role.Finance,
        Role.Medic,
        Role.Receptionist,
        Role.CustomerAdmin,
        Role.Customer,
        Role.Candidate,
      ],
    },
    {
      path: "/mfavalidate",
      name: "MfaChallenge",
      component: () => import("@/views/pages/MfaChallenge"),
      authorize: [
        Role.Admin,
        Role.Imms,
        Role.Staff,
        Role.Finance,
        Role.Medic,
        Role.Receptionist,
        Role.CustomerAdmin,
        Role.Customer,
        Role.Candidate,
      ],
    },
    {
      path: "/direct",
      name: "Direct",
      component: () => import("@/views/direct/DirectBooking"),
    },
    {
      path: "/urgent",
      name: "ForCause",
      component: () => import("@/views/pages/ForCause"),
    },
    {
      path: "/onsite",
      name: "OnSite",
      component: () => import("@/views/onsite/OnSite"),
    },
    {
      path: "/portal",
      component: () => import("@/views/portal/Index"),
      children: [
        {
          path: "onsite",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "OnSiteRequestList",
              path: "",
              component: () =>
                import("@/views/portal/onsite/OnSiteRequestList"),
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Staff],
              },
            },
            {
              name: "OnSiteRequestDetails",
              path: "details",
              component: () => import("@/views/portal/onsite/OnSiteDetails"),
              props: true,
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Staff],
              },
            },
          ],
          meta: {
            authorize: [Role.Admin, Role.Imms, Role.Staff],
          },
        },
        {
          path: "users",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Users",
              path: "",
              component: () => import("@/views/portal/users/UserList"),
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Finance,
                  Role.Medic,
                  Role.CustomerAdmin,
                ],
              },
            },
            {
              name: "EditUser",
              path: "edit/:user",
              component: () => import("@/views/portal/users/UserDetails"),
              props: true,
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Finance,
                  Role.Medic,
                  Role.CustomerAdmin,
                ],
              },
            },
            {
              path: "create",
              component: () => import("@/views/portal/users/UserDetails"),
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Finance,
                  Role.Medic,
                  Role.CustomerAdmin,
                ],
              },
            },
          ],
          meta: {
            authorize: [Role.Admin, Role.Imms, Role.Staff, Role.CustomerAdmin],
          },
        },
        {
          path: "bookings",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Bookings",
              path: "",
              component: () => import("@/views/portal/bookings/BookingList"),
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Finance,
                  Role.Medic,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerResults,
                  Role.CustomerInvoices,
                  Role.CustomerBooker,
                ],
              },
            },
            {
              name: "BookingEditor",
              path: "edit",
              component: () => import("@/views/portal/bookings/BookingEditor"),
              props: true,
              meta: {
                authorize: [Role.Admin, Role.Imms],
              },
            },
            {
              name: "BookingPriceView",
              path: "pricing",
              component: () =>
                import("@/views/portal/bookings/BookingPriceView"),
              props: true,
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Finance,
                  Role.Medic,
                ],
              },
            },
          ],
        },
        {
          path: "scheduling",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Schedule",
              path: "",
              component: BookingSchedule,
              props: true,
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Finance,
                  Role.Medic,
                  Role.Receptionist,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerBooker,
                ],
              },
            },
            {
              name: "BookingDetails",
              path: "booking",
              props: true,
              component: () =>
                import("@/views/portal/scheduling/BookingDetails"),
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Finance,
                  Role.Medic,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerBooker,
                  Role.CustomerInvoices,
                ],
              },
            },
          ],
        },
        {
          path: "finance",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Finance",
              path: "",
              component: () => import("@/views/portal/finance/Financials.vue"),
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Finance],
              },
            },
            {
              name: "InvoiceDetails",
              path: "invoice",
              props: true,
              component: () => import("@/views/portal/finance/Invoice.vue"),
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Finance],
              },
            },
            {
              name: "NewVoucherCode",
              path: "vouchercode",
              props: true,
              component: () =>
                import(
                  "@/views/portal/finance/components/VoucherCodeDetails.vue"
                ),
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Finance],
              },
            },
            {
              name: "EditVoucherCode",
              path: "vouchercode/edit/:id",
              props: true,
              component: () =>
                import(
                  "@/views/portal/finance/components/VoucherCodeDetails.vue"
                ),
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Finance],
              },
            },
          ],
        },
        {
          path: "audit",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "AuditEvents",
              path: "",
              component: () => import("@/views/portal/audit/AuditEvents.vue"),
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Finance, Role.Medic],
              },
            },
          ],
        },
        {
          path: "clinics",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Clinics",
              path: "",
              component: () => import("@/views/portal/clinics/ClinicList"),
              meta: {
                authorize: [Role.Admin, Role.Imms],
              },
            },
            {
              name: "EditClinic",
              path: "edit/:clinicId",
              component: () => import("@/views/portal/clinics/ClinicDetails"),
              props: true,
              meta: {
                authorize: [Role.Admin, Role.Imms],
              },
            },
            {
              name: "CreateClinic",
              path: "create",
              component: () => import("@/views/portal/clinics/ClinicDetails"),
              props: true,
              meta: {
                authorize: [Role.Admin, Role.Imms],
              },
            },
          ],
          meta: {
            authorize: [Role.Admin, Role.Imms],
          },
        },
        {
          name: "Invoices",
          path: "invoices",
          component: () => import("@/views/portal/invoices/InvoiceList"),
          meta: {
            authorize: [
              Role.Admin,
              Role.Imms,
              Role.Staff,
              Role.Finance,
              Role.CustomerAdmin,
              Role.Customer,
              Role.CustomerInvoices,
            ],
          },
        },
        {
          path: "customers",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Customers",
              path: "",
              component: () => import("@/views/portal/customers/CustomerList"),
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Finance,
                  Role.Medic,
                ],
              },
            },
            {
              name: "NewCustomer",
              path: "new",
              component: () =>
                import("@/views/portal/customers/CustomerDetails"),
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Finance,
                  Role.Medic,
                ],
              },
            },
            {
              name: "EditCustomer",
              path: "edit/:customer",
              component: () =>
                import("@/views/portal/customers/CustomerDetails"),
              props: true,
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Finance,
                  Role.Medic,
                ],
              },
            },
            {
              name: "CustomerImport",
              path: "import",
              component: () =>
                import("@/views/portal/customers/CustomerImport"),
              meta: {
                authorize: [Role.Admin, Role.Imms],
              },
            },
            {
              name: "CustomerTemplate",
              path: "template",
              component: () =>
                import("@/views/portal/customers/CustomerTemplate"),
              meta: {
                authorize: [Role.Admin, Role.Imms],
              },
            },
          ],
        },
        {
          name: "Schedules",
          path: "schedules",
          component: () => import("@/views/portal/schedules/RoomSchedule"),
          meta: {
            authorize: [Role.Admin, Role.Imms],
          },
        },
        {
          path: "procedures",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Procedures",
              path: "",
              component: () =>
                import("@/views/portal/procedures/ProcedureList"),
            },
            {
              name: "EditProcedure",
              path: "edit/:procedure",
              component: () =>
                import("@/views/portal/procedures/ProcedureDetails"),
              props: true,
            },
            {
              path: "create",
              component: () =>
                import("@/views/portal/procedures/ProcedureDetails"),
            },
            {
              path: "upload",
              component: () =>
                import("@/views/portal/procedures/UploadProcedures"),
            },
            {
              path: "template",
              component: () =>
                import("@/views/portal/procedures/ProcedureTemplate"),
            },
            {
              name: "ProcedureGroups",
              path: "groups",
              component: () =>
                import("@/views/portal/procedures/CustomerProcedureGroupList"),
            },
            {
              name: "ProcedureGroupDetails",
              path: "groupdetails",
              component: () =>
                import("@/views/portal/procedures/CustomerProcedureGroup"),
              props: true,
            },
          ],
          meta: {
            authorize: [Role.Admin, Role.Imms],
          },
        },
        {
          path: "certificates",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              path: "refdata/",
              component: () =>
                import("@/views/portal/certificates/ReferenceData"),
            },
            {
              path: "refdata/create",
              component: () =>
                import("@/views/portal/certificates/AddReference"),
            },
            {
              path: "refdata/:id",
              component: () =>
                import("@/views/portal/certificates/AddReference"),
            },
            {
              path: "templates",
              component: () => import("@/views/portal/certificates/Templates"),
            },
            {
              path: "templates/create",
              component: () =>
                import("@/views/portal/certificates/AddViewTemplate"),
            },
            {
              path: "templates/:id",
              component: () =>
                import("@/views/portal/certificates/AddViewTemplate"),
            },
            {
              path: "templatePreview/:id",
              component: () =>
                import("@/views/portal/certificates/PreviewTemplate"),
            },
            {
              path: "templatePreview/:id/:resultId",
              component: () =>
                import("@/views/portal/certificates/PreviewTemplate"),
            },
            {
              path: "medicalform/:id/:id1/:id2",
              component: () =>
                import("@/views/portal/certificates/MedicalForm"),
              meta: {
                authorize: [
                  Role.Imms,
                  Role.Admin,
                  Role.Medic,
                  Role.Staff,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerResults,
                ],
              },
            },
          ],
          meta: {
            authorize: [Role.Admin, Role.Imms],
          },
        },
        {
          path: "/",
          name: "Dashboard",
          component: () => import("@/views/portal/Dashboard"),
          meta: {
            authorize: [
              Role.Admin,
              Role.Imms,
              Role.Staff,
              Role.Finance,
              Role.Medic,
              Role.Receptionist,
              Role.CustomerAdmin,
              Role.Customer,
              Role.CustomerInvoices,
              Role.CustomerBooker,
              Role.CustomerResults,
              Role.Candidate,
            ],
          },
        },
        {
          path: "/pdf",
          name: "PdfViewer",
          component: () => import("@/views/pages/PdfViewer"),
          props: true,
          meta: {
            authorize: [
              Role.Admin,
              Role.Imms,
              Role.Finance,
              Role.CustomerAdmin,
              Role.Customer,
              Role.CustomerInvoices,
              Role.CustomerResults,
            ],
          },
        },
        {
          path: "appointments",
          name: "Appointments",
          component: () =>
            import("@/views/portal/appointments/AppointmentList"),
          meta: {
            authorize: [
              Role.Admin,
              Role.Imms,
              Role.Staff,
              Role.Finance,
              Role.Medic,
              Role.CustomerAdmin,
              Role.Customer,
              Role.Candidate,
              Role.Receptionist,
              Role.CustomerInvoices,
              Role.CustomerResults,
              Role.CustomerBooker,
            ],
          },
        },
        {
          path: "profile",
          name: "Profile",
          component: () => import("@/views/pages/UserProfile"),
          meta: {
            authorize: [
              Role.Admin,
              Role.Imms,
              Role.Staff,
              Role.Finance,
              Role.Medic,
              Role.CustomerAdmin,
              Role.Customer,
              Role.Candidate,
              Role.Receptionist,
            ],
          },
        },
        {
          path: "referrals",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Referrals",
              path: "",
              component: () => import("@/views/portal/referrals/Referrals"),
            },
            {
              name: "RequestReferal",
              path: "request",
              component: () =>
                import("@/views/portal/referrals/components/ReferralRequest"),
              props: true,
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Medic,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerBooker,
                ],
              },
            },
            {
              name: "EditReferral",
              path: "edit/:id",
              component: () =>
                import("@/views/portal/referrals/components/ReferralRequest"),
              props: true,
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Medic,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerBooker,
                ],
              },
            },
          ],
          meta: {
            authorize: [
              Role.Admin,
              Role.Imms,
              Role.Staff,
              Role.Finance,
              Role.Medic,
              Role.CustomerAdmin,
              Role.Customer,
              Role.CustomerResults,
              Role.CustomerBooker,
            ],
          },
        },

        {
          path: "questionnaires",
          component: () => import("@/views/components/EmptyRouterView"),
          children: [
            {
              name: "Questionnaires",
              path: "",
              component: () =>
                import("@/views/portal/questionnaires/Questionnaires"),
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Medic,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerResults,
                  Role.CustomerBooker,
                ],
              },
            },
            {
              name: "OnlineQuestionnaire",
              path: "online",
              component: () =>
                import("@/views/portal/questionnaires/OnlineQuestionnaire"),
              props: true,
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Staff, Role.Medic],
              },
            },
            {
              name: "SectionEditor",
              path: "edit",
              component: () =>
                import(
                  "@/views/portal/questionnaires/components/SectionEditor"
                ),
              props: true,
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Staff, Role.Medic],
              },
            },
            {
              name: "ReviewAnswers",
              path: "review",
              component: () =>
                import(
                  "@/views/portal/questionnaires/components/ReviewAnswers"
                ),
              props: true,
              meta: {
                authorize: [Role.Admin, Role.Imms, Role.Staff, Role.Medic],
              },
            },
            {
              name: "IssueToCandidate",
              path: "issue",
              component: () =>
                import("@/views/portal/questionnaires/IssueToCandidate"),
              props: true,
              meta: {
                authorize: [
                  Role.Admin,
                  Role.Imms,
                  Role.Staff,
                  Role.Medic,
                  Role.CustomerAdmin,
                  Role.Customer,
                  Role.CustomerBooker,
                ],
              },
            },
          ],
        },
      ],
    },
    {
      path: "/oq",
      component: () => import("@/views/oq/Index"),
      children: [
        {
          path: "login/:id",
          name: "QuestionnaireLogin",
          props: true,
          component: () => import("@/views/pages/QuestionnaireLogin"),
        },
        {
          name: "CandidateQuestionnaire",
          path: "candidate/:id",
          props: true,
          component: () => import("@/views/oq/CandidateQuestionnaire"),
          meta: {
            authorize: [Role.Admin, Role.Imms, Role.Staff, Role.Questionnaire],
          },
        },
        {
          path: "qthanks",
          name: "QuestionnaireThanks",
          component: () => import("@/views/pages/QuestionnaireThanks"),
        },
        {
          path: "rthanks",
          name: "ReferralThanks",
          component: () => import("@/views/pages/ReferralThanks"),
        },
        {
          path: "qcancelled",
          name: "QuestionnaireCancelled",
          component: () => import("@/views/pages/QuestionnaireCancelled"),
        },
      ],
    },
    {
      path: "/pages",
      name: "pages",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "Lock",
          path: "lock",
          component: () => import("@/views/pages/Lock"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;

  // Check to see if we are going to the login page, but are already logged in
  if (to.path === "/" && store.state.Auth.token) {
    return to.path === "/portal" ? next() : next({ path: "/portal" });
  }

  if (authorize) {
    // This route requires authorisation, do we have a token?
    if (!store.state.Auth.token) {
      // If the user is not authenticated or the details are stale, then redirect
      console.info(`navigating to root ... (return url is ${to.fullPath})`);
      return next({
        path: "/",
        query: {
          returnUrl: to.fullPath,
        },
      });
    } else {
      // OK, we have a token. Is it valid?
      TokenCheckService.get()
        .then(() => {
          next();
        })
        .catch((err) => {
          console.error(`token check failed: ${err}`);
          return next({
            path: "/",
            query: {
              returnUrl: to.fullPath,
            },
          });
        });
    }

    if (store.state.Auth.user.role === "questionnaire") {
      // We are dealing with a questionnaire user - route them accordinly
    }

    if (authorize.length && !authorize.includes(store.state.Auth.user.role)) {
      // OK, the user is navigating to somewhere they cannot go: route them to the portal home
      return next({ path: "/portal" });
    }
  }

  next();
});

export default router;
