import ApiService from './api.service';

const MedicalResultService = {
  list() {
    return ApiService.get('medicalresult');
  },
  getCategory(id) {
    return ApiService.get(`medicalresult/${id}`);
  },
};

export default MedicalResultService;
