import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import createLogger from 'vuex/dist/logger'

import modules from './modules'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vuexPersist = new VuexPersist({
  key: 'imms-app',
  storage: window.localStorage,
  modules: ['Auth', 'Session']
})

export default new Vuex.Store({
  modules,
  state: {
    procedures: [],
    locations: [],
    companies: []
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexPersist.plugin] : [vuexPersist.plugin]
})
