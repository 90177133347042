import ApiService from "./api.service";

const ScheduleEventService = {
  // Retrieve the schedule events for given date
  getForClinic(clinicId, queryData) {
    return ApiService.query(`scheduleevents/clinic/${clinicId}`, queryData);
  },
  getClinicEvents(clinicId, startDate, endDate) {
    return ApiService.query(`scheduleevents/clinicevents/${clinicId}`, {
      startDate: startDate,
      endDate: endDate,
    });
  },
  getSingle(eventId) {
    return ApiService.get(`scheduleevents/${eventId}`);
  },
  put(scheduleEvent) {
    return ApiService.put(`scheduleevents/${scheduleEvent.id}`, scheduleEvent);
  },
  // Post a scheduled event
  post(scheduleEvent) {
    return ApiService.post("scheduleevents", scheduleEvent);
  },
  delete(eventId) {
    return ApiService.delete(`scheduleevents/${eventId}`);
  },
};

export default ScheduleEventService;
