<template>
  <v-app>
    <v-snackbar
      :value="$store.state.Snackbar.snackbar.show"
      :multi-line="$store.state.Snackbar.snackbar.multiline"
      :top="$store.state.Snackbar.snackbar.top === 'top'"
      :bottom="$store.state.Snackbar.snackbar.bottom === 'bottom'"
      :color="$store.state.Snackbar.snackbar.color"
      :timeout="$store.state.Snackbar.snackbar.timeout"
    >
      {{ $store.state.Snackbar.snackbar.message }}
      <template #action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="$store.commit('Snackbar/setSnackbarShow', false)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view />
    <cookie-law theme="base" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: { CookieLaw },
  created() {
    // This prevents the user from being able to refresh and work around the MFA setup
    if (this.mfaSetupRequired === true) {
      return this.$router.push({
        name: "MfaSetup",
      });
    }

    // This prevents the user from being able to refresh and avoid the MFA challenge
    if (this.mfaRequired === true) {
      // Push the user to the challenge page
      return this.$router.push({
        name: "MfaChallenge",
      });
    }

    // Prevent the user from refreshing the page to avoid completing the password reset
    if (this.passwordResetIncomplete === true) {
      return this.$router.push({
        name: "ChangePassword",
      });
    }
  },
  computed: {
    ...mapGetters({
      mfaRequired: "Auth/mfaChallengeRequired",
      mfaSetupRequired: "Auth/mfaSetupRequired",
      passwordResetIncomplete: "Auth/passwordResetIncomplete",
    }),
  },
};
</script>

<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-image-editor/styles/material.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "assets/css/swal.css";
@import "../node_modules/vue-toast-notification/dist/theme-sugar.css";
</style>
