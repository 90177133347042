/**
 * Automatic import of modules and exports
 */
const requireModule = require.context('.', false, /\.js$/)
const modules = {}

requireModule.keys().forEach(filename => {
  if (filename === './index.js') return

  // Create the module name based on the file name
  const moduleName = filename.replace(/(\.\/|\.module\.js)/g, '').replace(/^\w/, c => c.toUpperCase())
  modules[moduleName] = requireModule(filename).default || requireModule(filename)
})

export default modules
