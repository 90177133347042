import Vue from "vue";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  DatePickerPlugin,
  DateRangePickerPlugin,
  DateTimePickerPlugin,
  TimePickerPlugin,
} from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import {
  NumericTextBoxPlugin,
  SignaturePlugin,
  UploaderPlugin,
} from "@syncfusion/ej2-vue-inputs";
import {
  SchedulePlugin,
  RecurrenceEditorPlugin,
} from "@syncfusion/ej2-vue-schedule";
import { RichTextEditorPlugin } from "@syncfusion/ej2-vue-richtexteditor";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { ImageEditorPlugin } from "@syncfusion/ej2-vue-image-editor";

enableRipple(true);
Vue.use(DatePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(DropDownListPlugin);
Vue.use(SchedulePlugin);
Vue.use(RecurrenceEditorPlugin);
Vue.use(UploaderPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(RichTextEditorPlugin);
Vue.use(SignaturePlugin);
Vue.use(GridPlugin);
Vue.use(ImageEditorPlugin);
