export const Role = {
  Imms: "imms",
  Admin: "admin",
  Staff: "staff",
  Finance: "finance",
  Medic: "medic",
  Receptionist: "receptionist",
  CustomerAdmin: "customer-admin",
  Customer: "customer",
  CustomerInvoices: "customer-invoices",
  CustomerResults: "customer-results",
  CustomerBooker: "customer-booker",
  Candidate: "candidate",
  Questionnaire: "questionnaire",
};
