import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";

const state = {
  token: JwtService.getToken() || "",
  status: "",
  user: {},
  errors: [],
  locked: false,
  routes: [],
  isPasswordResetRequired: false,
  isAccountOnHold: false,
  isQuestionnaireComplete: false,
  meta: {},
  isMfaSetupRequired: false,
  isMfaChallengeRequired: false,
  isQuestionnaireCancelled: false,
  isPasswordResetIncomplete: false,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  userRole: (state) => state.user.role,
  authenticationStatus: (state) => state.status,
  currentUser: (state) => state.user,
  getUserRoutes: (state) => state.routes,
  passwordChangeRequired: (state) => state.isPasswordResetRequired,
  customerId: (state) => state.user.customer_id,
  accountOnHold: (state) => state.isAccountOnHold,
  metaData: (state) => state.meta,
  questionnaireComplete: (state) => state.isQuestionnaireComplete,
  mfaSetupRequired: (state) => state.isMfaSetupRequired,
  mfaChallengeRequired: (state) => state.isMfaChallengeRequired,
  questionnaireCancelled: (state) => state.isQuestionnaireCancelled,
  passwordResetIncomplete: (status) => status.isPasswordResetIncomplete,
};

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      ApiService.post("/auth/login", user)
        .then((resp) => {
          commit("auth_success", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          console.error(
            `[AUTH] error when logging in: ${err} (${typeof err}) - ${
              err.message
            }`
          );
          commit("auth_error", err);
          JwtService.destroyToken();
          reject(err);
        });
    });
  },
  questionnaireLogin({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      ApiService.post(`/questionnaires/login/${user.id}`, user)
        .then((resp) => {
          commit("q_auth_success", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          console.error(
            `[Q_AUTH] error when logging in -> ${err} (${typeof err}) -> ${JSON.stringify(
              err
            )}`
          );
          commit("q_auth_error", err);
          JwtService.destroyToken();
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("auth_logout");
      localStorage.removeItem("imms_token");
      JwtService.destroyToken();
      resolve();
    });
  },
  checkAuth({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("user")
        .then((user) => {
          commit("auth_success", JwtService.getToken(), user.data);
        })
        .catch((err) => {
          commit("auth_error", err);
        });
    } else {
      commit("auth_logout");
    }
  },
  clearErrors({ commit }) {
    commit("auth_clear_errors");
  },
  lock({ commit }) {
    commit("auth_lock");
  },
  unlock({ commit }, password) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/unlock", {
        password: password,
      })
        .then((resp) => {
          if (resp.data.success) {
            commit("auth_unlock");
            resolve(resp);
          } else {
            reject(new Error("Invalid Credentials"));
          }
        })
        .catch((err) => {
          console.error(
            `[AUTH] error when logging in -> ${err} (${typeof err}) -> ${JSON.stringify(
              err
            )}`
          );
          reject(err);
        });
    });
  },
  loadRoutes({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/users/routes")
        .then((resp) => {
          if (resp.data.success) {
            commit("updateUserRoutes", resp.data.data);
            resolve(resp);
          } else {
            reject(new Error("Failed to Load Routes"));
          }
        })
        .catch((err) => {
          console.error(
            `[AUTH] failed to retrieve routes -> ${err} (${typeof err}) -> ${JSON.stringify(
              err
            )}`
          );
          reject(err);
        });
    });
  },
  setRoutes({ commit }, routes) {
    commit("updateUserRoutes", routes);
  },
  passwordReset({ commit }, isRequired) {
    commit("updatePassword", isRequired);
  },
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, payload) {
    state.status = "success";
    state.token = payload.token;
    state.user = payload.data;
    state.routes = payload.routes;
    state.isPasswordResetIncomplete = state.isPasswordResetRequired =
      state.user.changePasswordNextLogin;
    state.isAccountOnHold = payload.data.Customer
      ? payload.data.Customer.isLocked
      : false;
    state.isMfaSetupRequired = state.user.mfaSetupRequired;
    state.isMfaChallengeRequired = state.user.mfaChallengeRequired;
    JwtService.saveToken(payload.token);
    state.errors = {};
  },
  auth_error(state, error) {
    state.status = "error";
    state.errors = error;
    state.isPasswordResetRequired = false;
    state.isAccountOnHold = false;
    state.user = {};
    state.token = "";
  },
  auth_logout(state) {
    state.status = "";
    state.token = "";
    state.routes = "";
    state.user = {};
    state.meta = {};
    state.isPasswordResetRequired = false;
    state.isPasswordResetIncomplete = false;
    state.isAccountOnHold = false;
    state.isQuestionnaireComplete = false;
    state.isMfaSetupRequired = false;
    state.isMfaChallengeRequired = false;
    JwtService.destroyToken();
  },
  auth_clear_errors(state) {
    state.status = "";
    state.errors = [];
  },
  auth_lock(state) {
    state.locked = true;
  },
  auth_unlock(state) {
    state.locked = false;
  },
  updateUserRoutes(state, routes) {
    state.userRoutes = routes;
  },
  updatePasswordReset(state, isRequired) {
    state.isPasswordResetRequired = isRequired;
  },
  updatePasswordResetIncomplete(state, isIncomplete) {
    state.isPasswordResetIncomplete = isIncomplete;
  },
  q_auth_success(state, payload) {
    state.status = "success";
    state.token = payload.data.token;
    state.user = payload.data.user;
    state.meta = payload.data.questionnaire;
    state.isQuestionnaireComplete = Boolean(payload.data.user.isCompleted);
    state.isQuestionnaireCancelled =
      payload.data.user.QuestionnaireIssuance.status === "Cancelled";
    JwtService.saveToken(payload.data.token);
    state.errors = {};
  },
  q_auth_error(state, error) {
    state.status = "error";
    state.errors = error;
    state.isPasswordResetRequired = false;
    state.isAccountOnHold = false;
    state.user = {};
    state.token = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
