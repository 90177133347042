import { immsAxios } from "@/plugins/axios";

const ApiService = {
  query(resource, params) {
    return immsAxios.get(resource, { params: params });
  },

  get(resource, slug = "") {
    return immsAxios.get(`${resource}/${slug}`).catch((err) => {
      throw err;
    });
  },

  getFile(resource, params) {
    return immsAxios.get(`${resource}`, {
      responseType: "blob",
      params: params,
    });
  },

  getFileUsingPost(resource, params) {
    return immsAxios.post(`${resource}`, params, {
      responseType: "blob",
    });
  },

  post(resource, params) {
    return immsAxios.post(`${resource}`, params);
  },

  postFile(resource, params) {
    return immsAxios.post(resource, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  update(resource, slug, params) {
    return immsAxios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return immsAxios.put(`${resource}`, params);
  },

  delete(resource, params) {
    return immsAxios.delete(resource, params);
  },
};

export default ApiService;

export const DirectProceduresService = {
  get() {
    return ApiService.get("procedures/direct");
  },
};

export const UsersService = {
  get() {
    return ApiService.get("users");
  },
};

export const TokenCheckService = {
  get() {
    return ApiService.get("auth/verify");
  },
};
