import ApiService from "./api.service";
import moment from "moment";

const AppointmentService = {
  // Retrieve appointments for a given date
  get(clinicId, startDate, endDate) {
    const startRangeDate = moment(startDate).format("YYYY-MM-DD");
    const endRangeDate = moment(endDate).format("YYYY-MM-DD");

    // Retrieve the bookings for the current day and clinic
    return ApiService.query(`appointments/byclinic/${clinicId}`, {
      startDate: startRangeDate,
      endDate: endRangeDate,
    });
  },
  getAvailableSlots(params) {
    const businessDate = moment(params.date).format("YYYY-MM-DD");

    return ApiService.query(
      `appointments/slots/${params.roomId}/${businessDate}/${params.duration}`,
      {
        businessDate: businessDate,
        duration: params.duration,
        appointmentId: params.appointmentId,
      }
    );
  },
  getAvailableSlotsForClinic(params) {
    const businessDate = moment(params.date).format("YYYY-MM-DD");
    return ApiService.query(`appointments/clinicslots/${params.clinicId}`, {
      businessDate: businessDate,
      duration: params.duration,
    });
  },
  checkSlot(query) {
    return ApiService.query("appointments/available", query);
  },
  list(query) {
    return ApiService.query("appointments", query);
  },
  getSingle(appointmentId) {
    return ApiService.get(`appointments/${appointmentId}`);
  },
  getPrice(appointment) {
    return ApiService.post("appointments/price", appointment);
  },
  reserve(appointment) {
    return ApiService.post("appointments/reserve", appointment);
  },
  reserveDirect(appointment) {
    return ApiService.post("appointments/uareserve", appointment);
  },
  amend(appointment, sendEmail = false) {
    return ApiService.put(
      `appointments/${appointment.id}/${sendEmail}`,
      appointment
    );
  },
  amendDirect(appointment, sendEmail = false) {
    return ApiService.put(
      `appointments/amend/${appointment.id}/${sendEmail}`,
      appointment
    );
  },
  release(appointmentId) {
    return ApiService.delete(`appointments/reserve/${appointmentId}`);
  },
  post(appointment) {
    // Create a new appointment
    return ApiService.post("appointments", appointment);
  },
  put(appointment, sendEmail = false) {
    // Update the appointment
    return ApiService.put(
      `appointments/${appointment.id}/${sendEmail}`,
      appointment
    );
  },
  move(appointment) {
    // This is specific route for moving items in the schedule view
    return ApiService.put(`appointments/move/${appointment.Id}`, appointment);
  },
  delete(appointmentId, waiveCharges) {
    return ApiService.delete(`appointments/${appointmentId}/${waiveCharges}`);
  },
  updateAttendance(appointmentId, status, sendEmail = true) {
    return ApiService.post(
      `appointments/attendance/${appointmentId}/${sendEmail}`,
      {
        status: status,
      }
    );
  },
  updateAttendanceMultiple(appointments, status, sendEmail = true) {
    return ApiService.post(`appointments/attendance/multi/${sendEmail}`, {
      items: appointments,
      status: status,
    });
  },
  getExcelExport(query) {
    return ApiService.getFile("appointments/export", query);
  },
  getIcsFile(appointmentId) {
    return ApiService.getFile(`appointments/ics/${appointmentId}`);
  },
  getCoverLetter(appointmentId) {
    return ApiService.getFile(`appointments/pdf/${appointmentId}`);
  },
  async releaseAll(appointments) {
    return ApiService.post("appointments/releaseall", {
      appointments,
    });
  },
  checkClashingSlots(bookingId, data) {
    return ApiService.post(`appointments/movecheck/${bookingId}`, data);
  },
  replaceProcedure(data) {
    return ApiService.post(
      `appointments/changeprocedure/${data.appointment_id}`,
      data
    );
  },
  /**
   * Check whether charges apply to a cancellation
   */
  cancelCheck(appointmentId) {
    return ApiService.post(`appointments/cancelrequest/${appointmentId}`);
  },
  adminFastTrack(appointmentProcedureId) {
    return ApiService.post(`appointments/fasttrack/${appointmentProcedureId}`);
  },
};

export default AppointmentService;
