import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./plugins/sweetalert";
import "./plugins/syncfusion";
import Clipboard from "v-clipboard";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import VueParticles from "particles.vue";
import store from "./store";
import router from "./router";
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: { id: "UA-137811366-1" },
    includes: [
      {
        id: "GTM-TG2KJQ8",
        params: {
          send_page_view: false,
        },
      },
    ],
  },
  router
);

// import './registerServiceWorker';
import {
  loadCldr,
  registerLicense,
  setCulture,
  setCurrencyCode,
} from "@syncfusion/ej2-base";
import VueObserveVisibility from "vue-observe-visibility";
import VueDebounce from "vue-debounce";
import VueToast from "vue-toast-notification";
import { StripePlugin } from "@vue-stripe/vue-stripe";

// Register licensing for SyncFusion
registerLicense(process.env.VUE_APP_SYNCFUSION_KEY);

Vue.config.productionTip = false;

loadCldr(
  require("cldr-data/main/en-GB/numbers.json"),
  require("cldr-data/main/en-GB/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/en-GB/timeZoneNames.json")
);
setCulture("en-GB");
setCurrencyCode("GBP");

Vue.use(VueParticles);
Vue.use(Clipboard);
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: "mdi" });
Vue.use(VueObserveVisibility);
Vue.use(VueDebounce);
Vue.use(VueToast, {
  position: "top-right",
  pauseOnHover: true,
  dismissible: true,
});
Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_PUB_KEY,
  locale: "en-GB",
});

export default new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
