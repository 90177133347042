import ApiService from './api.service';

const ClinicService = {
  get() {
    return ApiService.get('clinics');
  },
  getSimpleList() {
    return ApiService.get('clinics/simple');
  },
  getDirect() {
    return ApiService.get('clinics/direct');
  },
  getSingle(clinic) {
    return ApiService.get(`clinics/${clinic.id}`);
  },
  getById(clinicId) {
    return ApiService.get(`clinics/${clinicId}`);
  },
  checkExisting(clinicName) {
    return ApiService.query('clinics/check', {
      clinicName: clinicName,
    });
  },
  post(clinic) {
    return ApiService.post('clinics', clinic);
  },
  put(clinic) {
    return ApiService.put(`clinics/${clinic.id}`, clinic);
  },
  delete(clinic) {
    return ApiService.delete(`clinics/${clinic.id}`);
  },
};

export default ClinicService;
