import axios from "axios";
import JwtService from "@/services/jwt.service";
import router from "@/router";
import store from "@/store";

// Instance for bookings functionality
const immsAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

// Instance to be used to access certificate functionality
const certAxios = axios.create({
  baseURL: process.env.VUE_APP_CERTAPI_URL,
});

immsAxios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

immsAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status !== 401 && error.response.status !== 403) {
      if (Array.isArray(error)) {
        Array.forEach((item, index) => {
          console.error(`${index} -> ${item}`);
        });
      }
      return Promise.reject(error);
    }

    if (error.response.status === 403) {
      // OK. The user doesn't have permission
      return Promise.reject(error);
    }

    // Catch all - authentication failed, so push them to the login page
    store
      .dispatch("Auth/logout")
      .then(() => {
        if (router.path !== "/") {
          router.push("/").catch((err) => {
            console.error(err);
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return Promise.reject(error);
  }
);

certAxios.interceptors.request.use(
  (config) => {
    // config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
    const authToken = localStorage.getItem("imms-app");
    const userDetails = JSON.parse(authToken).Auth.user;
    config.headers = {
      Authorization: `Bearer ${JwtService.getToken()}`,
      user: JSON.stringify(userDetails),
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

certAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { immsAxios, certAxios };
