const state = {
  clinic: null,
  bookingClinic: null,
  visibleRooms: [],
  currentBooking: {},
  scheduleTimeScale: {},
  procViewFilter: null,
  customerViewFilter: null,
  bookingViewFilter: null,
  invoiceViewFilter: {},
  custContactViewFilter: null,
  selectedDate: null,
  roomEvents: [],
  roomSchedule: [],
  invoiceOptions: null,
  invoicePage: 1,
  appointmentFilter: {},
  appointmentPage: 1,
  bookingFilter: {},
  bookingPage: 1,
  paymentsPage: 1,
  paymentsFilter: {},
  lastAuditTab: null,
  questionnaireTab: 0,
  referralTab: 0,
  financialsTab: 0,
};

const getters = {
  lastClinic: (state) => state.clinic,
  bookingClinic: (state) => state.bookingClinic,
  visibleRooms: (state) => state.visibleRooms,
  getCurrentBooking: (state) => state.currentBooking,
  getScheduleTimeScale: (state) => state.scheduleTimeScale,
  getProcViewFilter: (state) => state.procViewFilter,
  getCustomerViewFilter: (state) => state.customerViewFilter,
  getBookingViewFilter: (state) => state.bookingViewFilter,
  getInvoiceViewFilter: (state) => state.invoiceViewFilter,
  getCustContactViewFilter: (state) => state.custContactViewFilter,
  getSelectedDate: (state) => state.selectedDate,
  getRoomSchedule: (state) => state.roomSchedule,
  getRoomEvents: (state) => state.roomEvents,
  getInvoiceOptions: (state) => state.invoiceOptions,
  getAppointmentFilter: (state) => state.appointmentFilter,
  getInvoicePage: (state) => state.invoicePage || 1,
  getAppointmentPage: (state) => state.appointmentPage || 1,
  getBookingPage: (state) => state.bookingPage || 1,
  getPaymentsPage: (state) => state.paymentsPage || 1,
  getPaymentsFilter: (state) => state.paymentsFilter,
  getLastAuditTab: (state) => state.lastAuditTab || 0,
  getQuestionnaireTabPage: (state) => state.questionnaireTab || 0,
  getReferralTabPage: (state) => state.referralTab || 0,
  getLastFinancialsTabPage: (state) => state.financialsTab || 0,
};

const actions = {
  setClinic({ commit }, clinic) {
    commit("updateClinic", clinic);
  },
  setBookingClinic({ commit }, clinic) {
    commit("updateBookingClinic", clinic);
  },
  setVisibleRooms({ commit }, rooms) {
    commit("updateVisibleRooms", rooms);
  },
  setCurrentBooking({ commit }, booking) {
    commit("updateCurrentBooking", booking);
  },
  setScheduleTimeScale({ commit }, timeScale) {
    commit("updateScheduleTimeScale", timeScale);
  },
  setProcViewFilter({ commit }, filterText) {
    commit("updateProcViewFilter", filterText);
  },
  setCustomerViewFilter({ commit }, filterText) {
    commit("updateCustomerViewFilter", filterText);
  },
  setBookingViewFilter({ commit }, filterText) {
    commit("updateBookingViewFilter", filterText);
  },
  setInvoiceViewFilter({ commit }, filterData) {
    commit("updateInvoiceViewFilter", filterData);
  },
  setCustContactViewFilter({ commit }, filterText) {
    commit("updateCustContactFilter", filterText);
  },
  setSelectedDate({ commit }, selectedDate) {
    commit("updateSelectedDate", selectedDate);
  },
  setRoomSchedule({ commit }, roomSchedule) {
    commit("updateRoomSchedule", roomSchedule);
  },
  setRoomEvents({ commit }, roomEvents) {
    commit("updateRoomEvents", roomEvents);
  },
  setInvoiceOptions({ commit }, invoiceOptions) {
    commit("updateInvoiceOptions", invoiceOptions);
  },
  setAppointmentFilter({ commit }, appointmentFilter) {
    commit("updateAppointmentFilter", appointmentFilter);
  },
  setInvoicePage({ commit }, page) {
    commit("updateInvoicePage", page);
  },
  setAppointmentPage({ commit }, page) {
    commit("updateAppointmentPage", page);
  },
  setBookingPage({ commit }, page) {
    commit("updateBookingPage", page);
  },
  setPaymentsPage({ commit }, page) {
    commit("updatePaymentsPage", page);
  },
  setPaymentsFilter({ commit }, filter) {
    commit("updatePaymentsFilter", filter);
  },
  setLastAuditTab({ commit }, tab) {
    commit("updateLastAuditTab", tab);
  },
  setQuestionnaireTab({ commit }, tab) {
    commit("updateQuestionnaireTab", tab);
  },
  setReferralTab({ commit }, tab) {
    commit("updateReferralTab", tab);
  },
  setLastFinancialsTabPage({ commit }, tab) {
    commit("updateFinancialsTab", tab);
  },
};

const mutations = {
  updateClinic(state, clinic) {
    state.clinic = clinic;
  },
  updateBookingClinic(state, clinic) {
    state.bookingClinic = clinic;
  },
  updateVisibleRooms(state, rooms) {
    state.visibleRooms = rooms;
  },
  updateCurrentBooking(state, booking) {
    state.currentBooking = booking;
  },
  updateScheduleTimeScale(state, timeScale) {
    state.scheduleTimeScale = timeScale;
  },
  updateProcViewFilter(state, filterText) {
    state.procViewFilter = filterText;
  },
  updateCustomerViewFilter(state, filterText) {
    state.customerViewFilter = filterText;
  },
  updateBookingViewFilter(state, filterText) {
    state.bookingViewFilter = filterText;
  },
  updateInvoiceViewFilter(state, filterData) {
    state.invoiceViewFilter = filterData;
  },
  updateCustContactViewFilter(state, filterText) {
    state.custContactViewFilter = filterText;
  },
  updateSelectedDate(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  updateRoomEvents(state, roomEvents) {
    state.roomEvents = roomEvents;
  },
  updateRoomSchedule(state, roomSchedule) {
    state.roomSchedule = roomSchedule;
  },
  updateInvoiceOptions(state, invoiceOptions) {
    state.invoiceOptions = invoiceOptions;
  },
  updateAppointmentFilter(state, appointmentFilter) {
    state.appointmentFilter = appointmentFilter;
  },
  updateInvoicePage(state, page) {
    state.invoicePage = page;
  },
  updateAppointmentPage(state, page) {
    state.appointmentPage = page;
  },
  updateBookingPage(state, page) {
    state.bookingPage = page;
  },
  updatePaymentsPage(state, page) {
    state.paymentsPage = page;
  },
  updatePaymentsFilter(state, filter) {
    state.paymentsFilter = filter;
  },
  updateLastAuditTab(state, tab) {
    state.lastAuditTab = tab;
  },
  updateQuestionnaireTab(state, tab) {
    state.questionnaireTab = tab;
  },
  updateReferralTab(state, tab) {
    state.referralTab = tab;
  },
  updateFinancialsTab(state, tab) {
    state.financialsTab = tab;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
