<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <edit-appointment
      ref="editAppointment"
      @appointment-change="onAppointmentsChanged"
    />
    <schedule-event ref="scheduleEvent" />
    <schedule-event-list ref="scheduleEventList" />

    <v-card tile height="100%" style="position: relative">
      <v-toolbar color="primary white--text" class="pb-0">
        <v-toolbar-title>Select a Clinic to view Bookings:</v-toolbar-title>
        <v-spacer />
        <v-select
          v-model="clinic"
          dark
          :items="clinicData"
          label="Choose Clinic"
          single-line
          item-text="name"
          item-value="id"
          color="white--text"
          class="pt-5"
          @change="clinicChanged"
        />
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              color="success"
              class="ma-1"
              fab
              x-small
              @click="refreshAppointments()"
              v-on="{ ...tooltip }"
            >
              <v-icon class="white--text"> mdi-refresh </v-icon>
            </v-btn>
          </template>
          <span>Refresh Appointments</span>
        </v-tooltip>
        <div v-if="canScheduleEvents">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                color="success"
                class="ma-1"
                fab
                x-small
                @click="addScheduleEvent()"
                v-on="{ ...tooltip }"
              >
                <v-icon class="white--text"> mdi-alarm-plus </v-icon>
              </v-btn>
            </template>
            <span>Add Scheduled Event</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                color="success"
                class="ma-1"
                fab
                x-small
                @click="viewScheduleEvents()"
                v-on="{ ...tooltip }"
              >
                <v-icon class="white--text"> mdi-alarm-multiple </v-icon>
              </v-btn>
            </template>
            <span>Edit Scheduled Events</span>
          </v-tooltip>
        </div>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template #activator="{ on: menu }">
            <v-tooltip bottom>
              <template #activator="{ on: tooltip }">
                <v-btn
                  v-show="roomData != undefined && roomData.length > 1"
                  color="success"
                  class="ma-1"
                  fab
                  x-small
                  :disabled="!hasRooms"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon class="white--text"> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <span>Select Room schedules to display</span>
            </v-tooltip>
          </template>

          <v-card dense>
            <v-card-title class="pb-0"> Select Rooms to View: </v-card-title>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-checkbox
                    v-model="allRoomsSelected"
                    color="primary"
                    @click="selectAllRooms"
                  />
                </v-list-item-action>
                <v-list-item-content>Select All</v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item v-for="item in roomData" :key="item.id">
                <v-list-item-action>
                  <v-checkbox
                    v-model="item.visible"
                    color="primary"
                    @click="roomSelect"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />

            <v-card-actions>
              <v-spacer />

              <v-btn text small @click="menu = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-tooltip bottom>
          <schedule-settings
            ref="scheduleSettings"
            @settingssaved-event="onSettingsChanged"
          />
          <template #activator="{ on: tooltip }">
            <v-btn
              color="success"
              class="ma-1"
              fab
              x-small
              @click="settings()"
              v-on="{ ...tooltip }"
            >
              <v-icon class="white--text"> mdi-cog </v-icon>
            </v-btn>
          </template>
          <span>Change View Settings</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pa-0" style="height: 100%">
        <ejs-schedule
          id="schedule"
          ref="scheduleObject"
          :width="width"
          :height="height"
          :popup-open="popupOpen"
          :group="roomGroup"
          :selected-date="selectedDate"
          :event-settings="eventSettings"
          :start-hour="viewSettings.hours.startHour"
          :end-hour="viewSettings.hours.endHour"
          :readonly="readOnly"
          :show-header-bar="hasRooms"
          :apply-time-offset="applyTimeOffset"
          :time-scale="timeScale"
          :action-begin="onActionBegin"
          :action-complete="onActionComplete"
          :row-auto-height="viewSettings.rowAutoHeight"
          :drag-start="onDragStart"
          :drag-stop="onDragStop"
          :allow-resizing="allowResize"
          :data-bound="onDataBound"
          :render-cell="onRenderCell"
          :cell-click="onCellClicked"
          :allow-drag-and-drop="!readOnly && !isCustomer"
          :enableHtmlSanitizer="enableHtmlSanitizer"
        >
          <e-views>
            <e-view option="Day" />
            <e-view
              option="WorkWeek"
              :show-weekend="viewSettings.showWeekend"
            />
            <e-view option="Month" :show-weekend="viewSettings.showWeekend" />
          </e-views>
          <e-resources>
            <e-resource
              field="RoomId"
              title="Room"
              name="Rooms"
              :data-source="visibleRooms"
              :allow-multiple="allowMultiple"
              text-field="text"
              id-field="id"
              color-field="bookingColour"
              work-days-field="workDays"
              start-hour-field="startHour"
              end-hour-field="endHour"
            />
          </e-resources>
        </ejs-schedule>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";
import ClinicService from "@/services/clinic.service";
import RoomService from "@/services/room.service";
import AppointmentService from "@/services/appointment.service";
import {
  Resize,
  DragAndDrop,
  Day,
  WorkWeek,
  Month,
} from "@syncfusion/ej2-vue-schedule";
import { Internationalization } from "@syncfusion/ej2-base";
import ScheduleEventService from "../../../services/scheduleevent.service";

export default {
  components: {
    ScheduleSettings: () => import("./components/ScheduleSettings"),
    EditAppointment: () => import("./components/EditAppointment"),
    ScheduleEvent: () => import("./components/ScheduleEvent"),
    ScheduleEventList: () => import("./components/ScheduleEventList"),
  },
  provide: {
    schedule: [Day, WorkWeek, Month, Resize, DragAndDrop],
  },
  props: {
    displayDate: String,
    displayClinic: String,
  },
  data() {
    return {
      loading: true,
      applyTimeOffset: false,
      selectedDate: null,
      currentDate: null,
      sortOrder: "Ascending",
      fav: true,
      menu: false,
      message: false,
      hints: true,
      pauseRefresh: false,
      clinic: "",
      flag: true,
      width: "100%",
      height: "85vh",
      timer: "",
      scheduleObj: null,
      allRoomsOnSchedule: true,
      allowResize: false,
      allowMultiple: true,
      workDays: [1, 2, 3, 4, 5],
      timeScale: {
        enable: true,
        interval: 30,
        slotCount: 3,
        majorSlotTemplate: null,
        minorSlotTemplate: null,
      },
      enableHtmlSanitizer: false,
      viewSettings: {
        rowAutoHeight: true,
        hours: {
          startHour: "08:00",
          endHour: "19:00",
        },
        workHours: {
          highlight: true,
          start: "09:00",
          end: "17:30",
        },
        workDays: [1, 2, 3, 4, 5],
        showWeekend: false,
      },
      eventSettings: {
        enableTooltip: true,
        spannedEventPlacement: "TimeSlot",
        dataSource: [],
      },
      scrollOptions: {
        enable: true,
      },
      clinicList: [],
      roomData: [],
      roomEvents: [],
      appointments: [],
      dropDownClinic: "",
      clinicFields: {
        text: "name",
        value: "id",
      },
      roomGroup: {
        resources: ["Rooms"],
      },
      allRoomsSelected: true,
      isOnSite: false,
      isInternal: false,
      collator: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "Auth/currentUser",
      userRole: "Auth/userRole",
      userCustomerId: "Auth/customerId",
    }),
    isCustomer() {
      return this.userRole.startsWith("customer");
    },
    canScheduleEvents() {
      return !(this.isCustomer || this.userRole === "receptionist");
    },
    visibleRooms() {
      return this.roomData
        .filter((r) => r.visible === true)
        .sort((a, b) => this.collator.compare(a.text, b.text));
    },
    hasRooms() {
      return this.roomData != null && this.roomData.length > 0;
    },
    readOnly() {
      switch (this.userRole) {
        case "receptionist":
          return true;
        default:
          return false;
      }
    },
    clinicData() {
      return this.isCustomer === true
        ? this.clinicList.filter((x) => x.isOnSite === false)
        : this.clinicList;
    },
  },
  created() {
    // Initialise the collator we are using to sort room data
    this.collator = Intl.Collator("gb", {
      numeric: true,
      sensitivity: "base",
    });

    let currentDate = moment().add(1, "day").toDate();
    if (this.displayDate) {
      currentDate = moment(this.displayDate).toDate();
    } else {
      // Ensure that we are not on a weekend
      while (!(currentDate.getDay() % 6)) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Make sure we revert back to the date that the user was last looking at
      const lastSelectedDate = this.$store.getters["Session/getSelectedDate"];
      if (lastSelectedDate) {
        // Set the selected date as appropriate
        currentDate = lastSelectedDate;
      }
    }

    // Set the appropriate selected date in the view
    this.currentDate = this.selectedDate = currentDate;

    // If we have a saved timescale, restore that here
    const timeScale = this.$store.getters["Session/getScheduleTimeScale"];
    if (_.isEmpty(timeScale) === false) {
      Object.assign(this.timeScale, timeScale);
    }

    if (this.displayClinic) {
      // The user has specific the clinic to open, set that here
      this.clinic = this.displayClinic;
    } else {
      // Restore the clinic data
      this.clinic = this.$store.getters["Session/lastClinic"];
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    this.scheduleObj = this.$refs.scheduleObject.ej2Instances;

    // Refresh the data
    this.refresh();

    // Referesh the events for the room(s)
    this.refreshEvents();

    // Now we can set the timer
    this.refreshAppointments(this.scheduleObj);

    // Now start the timer
    this.timer = setInterval(() => {
      this.refreshAppointments(this.scheduleObj);
    }, 15000);
  },
  methods: {
    settings() {
      this.$refs.scheduleSettings.show("Schedule Settings", this.timeScale);
    },
    isClinicInternal() {
      // Find the clinic and return the state
      const clinic = this.clinicData.find((x) => x.id === this.clinic);

      // Default to false in the event that we can't find the clinic
      return clinic ? clinic.isInternalUse : false;
    },
    onDataBound() {
      if (this.flag) {
        const intl = new Internationalization();
        const scheduleObj = this.$refs.scheduleObject.ej2Instances;

        const showTime = this.displayDate
          ? new Date(this.displayDate)
          : new Date();

        // Adjust by 10 minutes to make it easier to see appointments
        showTime.setMinutes(showTime.getMinutes() - 10);
        scheduleObj.scrollTo(
          intl.formatDate(showTime, { skeleton: "Hm" }),
          undefined
        );
        this.flag = false;
      }
    },
    onRenderCell(args) {
      if (this.scheduleObj.currentView === "Month") {
        if (
          args.element.classList.contains("e-work-cells") &&
          !args.element.classList.contains("e-work-days")
        ) {
          args.element.style.background = "lightgrey";
        }
      } else {
        if (
          args.element.classList.contains("e-work-cells") &&
          !args.element.classList.contains("e-work-hours")
        ) {
          args.element.style.background = "lightgrey";
        }
      }
    },
    onSettingsChanged(timeScale) {
      this.timeScale = Object.assign({}, timeScale);
      const scheduleObj = this.$refs.scheduleObject.ej2Instances;
      if (scheduleObj) {
        scheduleObj.refresh();
      }
      this.$store.dispatch("Session/setScheduleTimeScale", timeScale);
    },
    refresh() {
      this.loading = true;

      ClinicService.get()
        .then((clinics) => {
          this.clinicList = clinics.data.data;
          // If we have a clinic, show that on the schedule
          if (this.clinic != null) {
            this.clinicChanged(this.clinic);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clinicChanged(clinic) {
      this.$store.dispatch("Session/setClinic", clinic);
      this.clinic = clinic;
      // OK. Is this on site?
      const onSiteClinic = this.clinicData.find((x) => x.id === this.clinic);
      if (onSiteClinic) {
        this.isOnSite = onSiteClinic.isOnSite;
        this.isInternal = onSiteClinic.isInternalUse;
      }
      this.refreshRooms();
    },
    refreshRooms() {
      RoomService.getScheduleByClinic(
        this.clinic,
        moment(this.currentDate).format("YYYY-MM-DD")
      )
        .then((rooms) => {
          // There is now additional information on this, so retrieve "rooms" from lower model
          this.roomData = rooms.data.data.schedule; // The room opening hours
          this.roomEvents = rooms.data.data.events; // Scheduled events for the room
          // Update the room schedule
          this.updateRoomSchedule();

          // Flag the rooms as visible
          this.roomData.forEach((item) => {
            this.$set(item, "visible", true);
          });

          this.viewSettings.hours.startHour =
            this.isOnSite || this.isInternal ? "00:00" : "07:00";
          this.viewSettings.hours.endHour =
            this.isOnSite || this.isInternal ? "00:00" : "19:00";

          // Make sure that teh events in the schedule are refreshed
          this.refreshEvents();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refreshEvents() {
      ScheduleEventService.getForClinic(this.clinic, {
        startDate: moment(this.currentDate).format("YYYY-MM-DD"),
        endDate: moment(this.currentDate).format("YYYY-MM-DD"),
      })
        .then((events) => {
          this.roomEvents = events.data.data;
          this.refreshAppointments();
        })
        .catch((err) => {
          console.error(
            `Failed to retrieve scheduled events for clinic -> ${err.message}`
          );
        });
    },
    refreshAppointments(scheduleObj) {
      this.loading = true;

      if (this.pauseRefresh === true) {
        // User is doing something in the schedule - stop refreshing for a while
        this.loading = false;
        return;
      }

      if (scheduleObj === undefined) {
        scheduleObj = this.$refs.scheduleObject.ej2Instances;
        if (scheduleObj == null) {
          console.warn(
            "schedule: unable to retrieve reference to the scheduler"
          );
          return;
        }
      }

      const viewDates = scheduleObj.getCurrentViewDates();
      if (viewDates === undefined) {
        this.loading = false;
        return;
      }

      if (this.clinic === undefined) {
        this.loading = false;
        return;
      }

      let startDate, endDate;

      // Use the "selected date" instead
      if (viewDates.length === 0) {
        startDate = endDate = this.currentDate;
      } else {
        startDate = viewDates[0];
        endDate = viewDates[viewDates.length - 1];
      }

      AppointmentService.get(this.clinic, startDate, endDate)
        .then((appointments) => {
          this.appointments = appointments.data.data;
          this.appointments = this.appointments.concat(this.roomEvents);
          this.$set(this.appointments, "dataSource", []);
          scheduleObj.eventSettings = { dataSource: this.appointments };
          scheduleObj.dataBind();
        })
        .catch((err) => {
          console.error(err);
        });
      this.loading = false;
    },
    updateRoomSchedule() {
      // Check to see if we are dealing with the MonthView
      if (this.scheduleObj.currentView === "Month") {
        this.roomData.forEach((r) => {
          delete r.startHour;
          delete r.endHour;
        });
      } else {
        const workDay = moment(this.currentDate).toDate().getDay();

        const roomSchedule = this.roomData.map((r) => ({
          id: r.id,
          schedule: r.schedule
            .filter((h) => h.dayNumber === workDay)
            .flat()
            .reduce((e) => ({
              startTime: e.startTime,
              finishTime: e.finishTime,
              isOpen: e.isOpen,
            })),
        }));

        this.roomData.forEach((room) => {
          // Resolve the startHour and endHour
          const itemSchedule = roomSchedule.filter((r) => r.id === room.id);
          room.startHour =
            itemSchedule[0].schedule.isOpen === true
              ? itemSchedule[0].schedule.startTime
              : "07:00";
          room.endHour =
            itemSchedule[0].schedule.isOpen === true
              ? itemSchedule[0].schedule.finishTime
              : "07:00";
        });
      }
    },
    onViewDateChanged() {
      const viewDates = this.scheduleObj.getCurrentViewDates();
      if (viewDates[0] === undefined) {
        this.currentDate = this.selectedDate;
      } else {
        this.currentDate = viewDates[0];
      }

      if (this.scheduleObj.currentView === "Day") {
        // Store the date in the profile
        this.$store.dispatch("Session/setSelectedDate", this.currentDate);
      }

      // Now retrieve the data, making sure we retrieve the correct room schedule
      this.updateRoomSchedule();
      this.refreshEvents();
      this.refreshAppointments(this.scheduleObj);
    },
    async checkSlotIsAvailable(args) {
      try {
        return await AppointmentService.checkSlot({
          room_id: args.data.RoomId,
          startTime: args.data.StartTime,
        });
      } catch (err) {
        if (err.response.status === 409) {
          // OK. This slot is not available, most likely a clash or the room is not open
          this.$toast.error(err.response.data.message);
        } else {
          this.$toast.error(
            `Error when checking slot availability: ${err.message}`
          );
        }
        return false;
      }
    },
    checkRestriction(args) {
      // Find the room we are dealing with
      const room = this.roomData.find((x) => x.id === args.data.RoomId);
      if (room === null) {
        console.error(
          `Failed to check room restrictions - missing room? ${args.data.RoomId} not in this.roomData`
        );
        return true;
      }

      // If this room is restricted, then only admin and medics can book in there
      if (
        room.isRestrictedBooking &&
        this.userRole !== "admin" &&
        this.userRole !== "medic"
      ) {
        return false;
      }
      return true;
    },
    async popupOpen(args) {
      args.cancel = true;

      // Is this firing due to a validation error
      if (args.type === "ValidationAlert") {
        return;
      }

      // Are we clicking on an existing appointment?
      if (args.data.Id) {
        if (args.data.Status === "reserved") {
          this.$swal({
            title: "Booking in Progress",
            html: "Apologies, but you cannot edit bookings that are still being made and have not yet completed.",
            footer: "Please try again later",
          });
        } else {
          this.$refs.editAppointment
            .show("Edit Appointment", args.data.Id, this.readOnly, {})
            .then(() => {
              // Refresh the appointments if we have made changes (defeprred to next refresh)
              this.refreshAppointments(this.scheduleObj);
            });
        }
        return;
      }

      // Check that the user is allow to make a booking
      if (this.userRole.startsWith("customer")) {
        // Is the user allowed to direct book?
        if (
          !this.currentUser.customers.some((x) => x.isDirectBooking === true)
        ) {
          this.$swal({
            title: "Permission Denied",
            html: "Apologies, but you do not have permission to make bookings at this time.<br /><br />Please contact Express Medicals if you think you are seeing this message in error.",
          });
          return;
        }
      }

      // Check that the start time is in the future :)
      const dateNow = moment();
      const chosenDate = moment(args.data.StartTime);
      if (chosenDate.isBefore(dateNow) && !this.isInternal) {
        this.$swal({
          title: "Not Allowed",
          html: "Apologies, but you cannot create historic appointments",
        });
        args.cancel = true;
        return;
      }

      // Check for working hours
      if (args.target.classList.contains("e-work-hours") === false) {
        this.$swal({
          title: "Not Allowed",
          html: "Apologies, but the clinic is closed at that time",
        });
        args.cancel = true;
        return;
      }

      // Check for weekend
      if (
        !this.isClinicInternal() &&
        (chosenDate.day() === 0 || chosenDate.day() === 6)
      ) {
        this.$swal({
          title: "Not Allowed",
          html: "Apologies, but you cannot create weekend appointments as our clinics are closed",
        });
        args.cancel = true;
        return;
      }

      if (!this.scheduleObj.isSlotAvailable(args.data)) {
        this.$swal({
          title: "Not Allowed",
          html: "Apologies, but you cannot create appointments in an allocated slot",
        });
        args.cancel = true;
        return;
      }

      const isAvailable = await this.checkSlotIsAvailable(args);
      if (!isAvailable) {
        this.$swal({
          title: "Slot Booked",
          html: "Apologies, but this slot is not available.<br>Please select another appointment time or room.",
        }).then(() => {
          this.refreshAppointments();
        });
        return;
      }

      if (this.checkRestriction(args) === false) {
        this.$swal({
          title: "Restricted",
          html: "Apologies, but bookings in this room are restricted.<br>Please contact an Admin user.",
        }).then(() => {
          this.refreshAppointments();
        });
        return;
      }

      if (chosenDate.isSame(dateNow, "day")) {
        if (this.isCustomer) {
          this.$swal({
            title: "Not Allowed",
            html: "Apologies, but you cannot create same day appointments. Please contact our booking team on +44 (0) 207 500 6900",
          });
          args.cancel = true;
        } else {
          this.$swal({
            title: "Warning!",
            html: "You are making a booking for <strong>today</strong> - is that correct?",
            showCancelButton: true,
            confirmButtonColor: "#E65100",
          }).then((result) => {
            if (result.isConfirmed === false) return;
            this.$router.push({
              name: "BookingDetails",
              params: {
                clinic: this.clinic,
                room: this.roomData[args.data.groupIndex].id,
                bookingDate: args.data.StartTime,
              },
            });
          });
        }
      } else {
        // Check that the slot being selected is actually available
        // OK. We can continue
        this.$router.push({
          name: "BookingDetails",
          params: {
            clinic: this.clinic,
            room: args.data.RoomId,
            bookingDate: args.data.StartTime,
          },
        });
      }
    },
    eventClick(args) {
      // The user has clicked an event within the schedule
      args.cancel = true;
    },
    onDragStart() {
      this.pauseRefresh = true;
    },
    onDragStop() {
      this.pauseRefresh = false;
    },
    onActionComplete(args) {
      const scheduleObj = this.$refs.scheduleObject.ej2Instances;

      if (
        args.requestType === "viewNavigate" ||
        args.requestType === "dateNavigate"
      ) {
        this.onViewDateChanged();
      } else if (args.requestType === "eventChanged") {
        // Only allow change if the slot is available
        if (!scheduleObj.isSlotAvailable(args.data[0])) {
          args.cancel = true;
        }

        // Confirm the move...
        this.$swal({
          title: "Move Appointment",
          html: `Are you sure you want to move the appointment<br><strong>${args.data[0].Location.replace(
            "<br>",
            ", "
          )}</strong> for candidate <strong>${
            args.data[0].Subject
          }</strong> to<br><strong>${args.data[0].StartTime.toLocaleString(
            "en-GB"
          )}</strong>?`,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#d33",
          cancelButtonText: "No",
        })
          .then((result) => {
            if (result.isConfirmed) {
              // Update the booking accordingly
              args.data[0].actionType = "drag-and-drop";
              AppointmentService.move(args.data[0])
                .then(() => {
                  this.refreshAppointments(this.scheduleObj);
                })
                .catch((err) => {
                  this.$swal({
                    title: "Update Failed",
                    html: "The appointment count not be moved.",
                    icon: "error",
                    footer: `Error: ${err.response.data.message}`,
                  }).then(() => {
                    args.cancel = true;
                  });
                });
            } else {
              args.cancel = true;
              this.refreshAppointments(this.scheduleObj);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    onActionBegin(args) {
      const scheduleObj = this.$refs.scheduleObject.ej2Instances;
      if (
        args.requestType === "eventCreate" ||
        args.requestType === "eventChange"
      ) {
        let data;

        if (args.requestType === "eventCreate") {
          data = args.data[0];
        } else if (args.requestType === "eventChange") {
          data = args.data;
        }

        if (data.IsLocked) {
          // Prevent the drag and drop
          args.cancel = true;
        }

        // Only allow change if the slot is available
        if (!scheduleObj.isSlotAvailable(data)) {
          args.cancel = true;
        }

        const dateNow = moment();
        const chosenDate = moment(data.EndTime);
        if (chosenDate.isBefore(dateNow) && !this.isClinicInternal()) {
          args.cancel = true;
        }
      } else {
        args.cancel = false;
      }
    },
    onAppointmentsChanged(refresh) {
      if (refresh) {
        this.refreshAppointments(this.scheduleObj);
      }
    },
    addScheduleEvent() {
      this.$refs.scheduleEvent
        .show("Add Schedule Event", this.clinic, null)
        .then(() => {
          this.refreshEvents();
          this.$nextTick(this.refreshAppointments(this.scheduleObj));
        });
    },
    viewScheduleEvents() {
      this.$refs.scheduleEventList
        .show("Scheduled Events", this.clinic)
        .then(() => {
          this.refreshEvents();
        });
    },
    selectAllRooms() {
      if (this.allRoomsSelected) {
        this.roomData.forEach((r) => (r.visible = true));
      }
    },
    roomSelect() {
      this.allRoomsSelected = false;
    },
    onCellClicked(args) {
      if (this.scheduleObj.currentView === "Month") {
        this.scheduleObj.currentView = "Day";
        this.selectedDate = args.startTime;
        args.cancel = true;
      }
    },
  },
};
</script>

<style>
.e-disabled .e-weekend {
  color: darkgray;
}

.e-schedule .e-block-appointment {
  color: black;
  background: linear-gradient(45deg, #aaa, #eee);
  font-weight: 500;
  text-align: center;
}

/*
.e-schedule .e-vertical-view .e-work-hours {
  background-color: #3cc8d63d;
}*/
</style>
