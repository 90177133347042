import ApiService from './api.service';

const AccountingService = {
  getTaxRates() {
    return ApiService.get('accounting/taxrates');
  },
  getNominalCodes() {
    return ApiService.get('accounting/nominals');
  },
  getDepartments() {
    return ApiService.get('accounting/departments');
  },
  getSingleTaxRate(taxRate) {
    return ApiService.get(`accounting/taxrates/${taxRate.id}`);
  },
  getPaymentTypes() {
    return ApiService.get('accounting/paymenttypes');
  },
  getDirectPaymentType() {
    return ApiService.get('accounting/directpayment');
  },
  getSinglePaymentType(id) {
    return ApiService.get(`accounting/paymenttypes/${id}`);
  },
  postTaxRate(taxRate) {
    return ApiService.post('accounting/taxrates', taxRate);
  },
  postNominalCode(nominalCode) {
    return ApiService.post('accounting/nominals', nominalCode);
  },
  putTaxRate(taxRate) {
    return ApiService.put(`accounting/taxrates/${taxRate.id}`, taxRate);
  },
  deleteTaxRate(taxRate) {
    return ApiService.delete(`accounting/taxrates/${taxRate.id}`);
  },
};

export default AccountingService;
