import AccountingService from "@/services/accounting.service";
import ClinicService from "@/services/clinic.service";
import MedicalResultService from "../../services/medicalresult.service";
import RoomService from "@/services/room.service";
import UserService from "../../services/users.service";

const state = {
  clinics: null,
  rooms: null,
  taxRates: null,
  paymentTypes: null,
  userRoles: null,
  medicalResults: null,
};

const getters = {
  hasClinics: (state) => !!state.clinics,
  getClinics: (state) => state.clinics,
  hasRooms: (state) => !!state.rooms,
  getRooms: (state) => state.rooms,
  hasTaxRates: (state) => !!state.taxRates,
  getTaxRates: (state) => state.taxRates,
  getPaymentTypes: (state) => state.paymentTypes,
  getUserRoles: (state) => state.userRoles,
  getMedicalResults: (state) => state.medicalResults,
};

const actions = {
  fetchClinics({ commit }) {
    return new Promise((resolve, reject) => {
      ClinicService.get()
        .then((res) => {
          commit("updateClinics", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(`Failed to load the clinic information: ${err}`);
          commit("updateClinics", []);
          reject(err);
        });
    });
  },
  fetchRooms({ commit }) {
    return new Promise((resolve, reject) => {
      RoomService.get()
        .then((res) => {
          commit("updateRooms", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("updateRooms", []);
          reject(err);
        });
    });
  },
  fetchTaxRates({ commit }) {
    return new Promise((resolve, reject) => {
      AccountingService.getTaxRates()
        .then((res) => {
          commit("updateTaxRates", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("updateTaxRates", []);
          reject(err);
        });
    });
  },
  fetchPaymentTypes({ commit }) {
    return new Promise((resolve, reject) => {
      AccountingService.getPaymentTypes()
        .then((res) => {
          commit("updatePaymentTypes", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("updatePaymentTypes", []);
          reject(err);
        });
    });
  },
  fetchUserRoles({ commit }) {
    return new Promise((resolve, reject) => {
      UserService.getUserRoles()
        .then((res) => {
          commit("updateUserRoles", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("updateUserRoles", []);
          reject(err);
        });
    });
  },
  fetchMedicalResults({ commit }) {
    return new Promise((resolve, reject) => {
      MedicalResultService.list()
        .then((result) => {
          commit("updateMedicalResults", result.data.data);
          resolve(result.data.data);
        })
        .catch((err) => {
          commit("updateMedicalResults", []);
          reject(err);
        });
    });
  },
};

const mutations = {
  updateClinics(state, clinics) {
    state.clinics = clinics;
  },
  updateRooms(state, rooms) {
    state.rooms = rooms;
  },
  updateTaxRates(state, taxRates) {
    state.taxRates = taxRates;
  },
  updatePaymentTypes(state, paymentTypes) {
    state.paymentTypes = paymentTypes;
  },
  updateUserRoles(state, userRoles) {
    state.userRoles = userRoles;
  },
  updateMedicalResults(state, medicalResults) {
    state.medicalResults = medicalResults;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
